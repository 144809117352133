<template>
  <div class="reviewed" style="text-align: left;">
    <van-nav-bar
        title="待审核"
        left-text="返回"
        left-arrow
        @click-left="onReturn"
    />

    <van-list
        v-model="loading"
        v-if="!isDataEmpty"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
    >
      <van-cell v-for="(item,index) in list" :key="index" :title="item.createTime" :icon="icon_todo_date" :is-link="item.taskType != 1 && item.taskType != 4" :to="item.taskType != 1 && item.taskType != 4?'orderDetail?orderNo='+item.taskId:''">
        <template #label>
          <van-icon :name="icon_huanhuo" v-if="item.orderType == 4 || item.orderType == 5" />
          <span style="font-size: 18px;color: #565555;">{{item.title}}</span>
          <van-divider style="margin: 10px 0;" />
          <span style="font-size: 15px;line-height: 30px;">{{item.taskId}}</span>
          <van-button v-if="item.taskType != 1 && item.taskType != 4" plain type="info" size="small" style="float: right;width:100px;" @click="cancelOrder(item.taskId);">取消订货</van-button>
        </template>
      </van-cell>
    </van-list>

    <van-empty description="没有数据哦" v-if="isDataEmpty" />
  </div>
</template>

<script>
import icon_todo_date from "@/assets/icon_todo_date.png";
import icon_huanhuo  from "@/assets/icon_huanhuo.png";
import { myTaskList, cancelOrderGoods } from "@/api/order";
import { Toast } from 'vant';

export default {
  name: "Reviewed",
  components: {},
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      icon_todo_date: icon_todo_date,
      icon_huanhuo: icon_huanhuo,
      pageNo: 0,
      pageSize: 10,
      isDataEmpty: false,
    };
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
    },
    onLoad() {
      this.pageNo++;
      myTaskList(2, this.pageNo, this.pageSize).then(response => {
        console.log(response.data);
        let data = response.data;
        // 加载状态结束
        this.loading = false;
        // 没有数据
        if(data.total == 0) {
          this.isDataEmpty = true;
          this.finished = true;
        } else {
          for(let i in data.records) {
            let item = data.records[i];
            console.log(item);
            this.list.push({id:item.id, taskId:item.taskId, createTime: item.createTime.replace('T',' '), title: item.title, orderType: item.orderType, taskType: item.taskType});
          }
          if(data.current == data.pages) {
            // 数据全部加载完成
            this.finished = true;
          }
        }
      });
    },
    cancelOrder(taskId) {
      event.stopPropagation();
      cancelOrderGoods(taskId).then(r => {
        console.log(r);
        Toast.success('取消订货成功');
        this.list = [];
        this.pageNo = 0;
        this.isDataEmpty = false;
        this.loading = false;
        this.finished = false;
      });
    },
    onReturn() {
      this.$router.go(-1);
    }
  }
};
</script>
<style lang="scss" scoped>
.reviewed {
  background: #f5f5f5;
}
.van-cell__title > span {
 color:#a5a0a0;
}
.van-cell {
  font-size: 16px;
  margin-bottom: 10px;
}
.van-icon__image {
  width: 1.2em;
  height: 1.2em;
}
.van-button--small {
  font-size: 14px;
}
</style>
